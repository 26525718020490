import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EventDetailsExternalCard from "../EventDetailsExternal/EventDetailsExternalCard";
import Logo from "../../assets/images/logo-new 1.svg";
import HeaderExternal from "../Header/HeaderExternal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f7f8fc",
    padding: 31,
  },
  main: {
    padding: `${theme.spacing(2.75)} ${theme.spacing(2)}`,
    display: "flex",
    flexDirection: "column",
    marginTop: 50,
    borderRadius: 12,
    boxShadow: "0px 3px 12px 0px #0000001F",
    backgroundColor: "white",
    height: "auto",
    flexWrap: "wrap",
  },

  cardContainer: {
    flex: 1,
    display: "flex",
    position: "relative",
    gap: 30,
    flexWrap: "wrap",
    padding: theme.spacing(2),
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 15,
  },
}));

const EventExternal = () => {
  const classes = useStyles();

  const [bookmarkedEvents, setBookmarkedEvents] = React.useState([]);

  React.useEffect(() => {
    const storedBookmarks = JSON.parse(localStorage.getItem("bookmarks")) || [];
    setBookmarkedEvents(storedBookmarks);
  }, []);

  return (
    <>
      <HeaderExternal />
      <Box className={classes.root}>
        <Box className={classes.main}>
          <Box className={classes.cardContainer}>
            {bookmarkedEvents?.map((event) => {
              return (
                <EventDetailsExternalCard
                  data={event.data}
                  monitoringDataCatalog={event.data.monitoringDataCatalog}
                  monitoringId={event.id}
                  key={event.id}
                />
              );
            })}
          </Box>
          <Box className={classes.footer}>
            <Typography> Operated by </Typography>
            <img src={Logo} alt="Logo" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EventExternal;
