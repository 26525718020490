export const getTitleTooltipcurrency = (value, currency="") =>{
  if (currency === 'INR' ){
    if (value.includes('Ar')) {
      return 'Ar is Arab, and 1 Arab equals 100 Crore.';
    }
    if (value.includes('L')) {
      return 'L is Lakh, and 1 Lakh equals 100 Thousand (K).';
    }
    if (value.includes('Cr')) {
      return 'Cr is Crore, and 1 Crore equals 100 Lakh.';
    }
    if (value.includes('Kh')) {
      return 'Kh is Kharab, and 1 Kharab equals 100 Arab.';
    }

    return value;
  }

  return value
}