import { connect } from "react-redux";

import {
  canAddStatisticsSelector,
  resolvedStatisticsRecordsSelector,
  selectedDatasetSelector,
  selectedDatasetTimeLabelSelector,
  selectedPlaceInfoSelector,
  selectedVariableSelector,
} from "../selectors/controlSelectors";
import _StatisticsPanel from "../components/StatisticsPanel";
import { addStatistics, removeStatistics } from "../actions/dataActions";
import { postMessage } from "../actions/messageLogActions";
import { statisticsLoadingSelector } from "../selectors/dataSelectors";

const mapStateToProps = (state) => {
  return {
    selectedDataset: selectedDatasetSelector(state),
    selectedVariable: selectedVariableSelector(state),
    selectedTime: selectedDatasetTimeLabelSelector(state),
    selectedPlaceInfo: selectedPlaceInfoSelector(state),
    statisticsLoading: statisticsLoadingSelector(state),
    statisticsRecords: resolvedStatisticsRecordsSelector(state),
    canAddStatistics: canAddStatisticsSelector(state),
  };
};

const mapDispatchToProps = {
  addStatistics,
  removeStatistics,
  postMessage,
};

const StatisticsPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_StatisticsPanel);
export default StatisticsPanel;
