import Box from "@mui/material/Box";
import { makeStyles } from "../../util/styles";
import StatisticsDataRow from "./StatisticsDataRow";
import StatisticsFirstRow from "./StatisticsFirstRow";

const styles = makeStyles({
  container: {
    padding: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

export default function StatisticsPanel({
  selectedDataset,
  selectedVariable,
  selectedTime,
  selectedPlaceInfo,
  statisticsLoading,
  statisticsRecords,
  canAddStatistics,
  addStatistics,
  removeStatistics,
  postMessage,
}) {
  return (
    <Box sx={styles.container}>
      <StatisticsFirstRow
        selectedDataset={selectedDataset}
        selectedVariable={selectedVariable}
        selectedTime={selectedTime}
        selectedPlaceInfo={selectedPlaceInfo}
        canAddStatistics={canAddStatistics}
        addStatistics={addStatistics}
        statisticsLoading={statisticsLoading}
      />
      {statisticsRecords.map((sr, rowIndex) => (
        <StatisticsDataRow
          key={rowIndex}
          statisticsRecord={sr}
          rowIndex={rowIndex}
          removeStatistics={removeStatistics}
          postMessage={postMessage}
        />
      ))}
    </Box>
  );
}
