import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { makeStyles } from "../../util/styles";
import i18n from "../../i18n";
import { isoDateTimeStringToLabel } from "../../util/time";

const styles = makeStyles({
  container: {
    padding: 1,
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 0.5,
  },
  actions: {
    display: "flex",
    gap: 0.1,
  },
  body: {
    display: "flex",
  },
});

function Missing({ phrase }) {
  return <span style={{ color: "red" }}>{`<${i18n.get(phrase)}?>`}</span>;
}

export default function StatisticsRow({
  dataset,
  variable,
  time,
  placeInfo,
  actions,
  body,
  containerRef,
}) {
  const datasetLabel = dataset ? dataset.title : <Missing phrase="Dataset" />;
  const variableLabel = variable ? (
    variable.name
  ) : (
    <Missing phrase="Variable" />
  );
  const timeLabel = time ? (
    isoDateTimeStringToLabel(time)
  ) : (
    <Missing phrase="Time" />
  );
  const placeLabel = placeInfo ? placeInfo.label : <Missing phrase="Place" />;
  return (
    <Box sx={styles.container} ref={containerRef}>
      <Box sx={styles.header}>
        <Typography fontSize="small">
          {datasetLabel} / {variableLabel}, {timeLabel}, {placeLabel}
        </Typography>
        <Box sx={styles.actions}>{actions}</Box>
      </Box>
      {body && <Box sx={styles.body}>{body}</Box>}
    </Box>
  );
}
