import {
  blue,
  brown,
  cyan,
  green,
  indigo,
  lightBlue,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";

import rawDefaultConfig from "./resources/config.json";
import { parseBranding } from "./util/branding";
import baseUrl from "./util/baseurl";
import { buildPath } from "./util/path";
import { isNumber } from "./util/types";

export const appParams = new URLSearchParams(window.location.search);

export class Config {
  constructor(name, server, branding, authClient) {
    this.name = name;
    this.server = server;
    this.branding = branding;
    this.authClient = authClient;
  }

  static async load() {
    let configPath = appParams.get("configPath") || "config";
    const rawConfig = await this.loadRawConfig(configPath);
    if (rawConfig === rawDefaultConfig) {
      configPath = "";
    }

    const name = rawConfig.name || "default";
    const authClient = this.getAuthConfig(rawConfig);
    const server = this.getServerConfig(rawConfig);
    const compact = parseInt(appParams.get("compact") || "0") !== 0;
    let branding = parseBranding(
      {
        ...rawDefaultConfig.branding,
        ...rawConfig.branding,
        compact: compact || rawConfig.branding.compact,
      },
      configPath
    );
    branding = decodeBrandingFlag(branding, "allowUserVariables");
    branding = decodeBrandingFlag(branding, "allow3D");
    Config._instance = new Config(name, server, branding, authClient);
    if (branding.windowTitle) {
      this.changeWindowTitle(branding.windowTitle);
    }
    if (branding.windowIcon) {
      this.changeWindowIcon(branding.windowIcon);
    }
    return Config._instance;
  }

  static getAuthConfig(rawConfig) {
    let authClient = rawConfig.authClient && { ...rawConfig.authClient };
    const authClientFromEnv = Config.getAuthClientFromEnv();
    if (
      !authClient &&
      authClientFromEnv.authority &&
      authClientFromEnv.clientId
    ) {
      authClient = {
        authority: authClientFromEnv.authority,
        client_id: authClientFromEnv.clientId,
      };
    }
    if (authClient) {
      if (authClientFromEnv.authority) {
        authClient.authority = authClientFromEnv.authority;
      }
      if (authClientFromEnv.clientId) {
        authClient.client_id = authClientFromEnv.clientId;
      }
      if (authClientFromEnv.audience) {
        const { extraQueryParams = {} } = authClient;
        authClient.extraQueryParams = {
          ...extraQueryParams,
          audience: authClientFromEnv.audience,
        };
      }
    }
    return authClient;
  }

  static getServerConfig(rawConfig) {
    const server = {
      ...rawDefaultConfig.server,
      ...rawConfig.server,
    };
    const serverFromEnv = Config.getApiServerFromEnv();
    server.id = appParams.get("serverId") || serverFromEnv.id || server.id;
    server.name = appParams.get("serverName") || serverFromEnv.name || server.name;
    server.url = appParams.get("serverUrl") || serverFromEnv.url || server.url;
    return server;
  }

  static async loadRawConfig(configPath) {
    let rawConfig = null;
    let rawConfigError = null;
    const configUrl = buildPath(baseUrl.href, configPath, "config.json");
    try {
      const rawConfigResponse = await fetch(configUrl);
      if (rawConfigResponse.ok) {
        rawConfig = await rawConfigResponse.json();
      } else {
        const { status, statusText } = rawConfigResponse;
        rawConfigError = `HTTP status ${status}`;
        if (statusText) {
          rawConfigError += ` (${statusText})`;
        }
      }
    } catch (e) {
      rawConfigError = `${e}`;
    }
    if (rawConfig === null) {
      rawConfig = rawDefaultConfig;
    }
    return rawConfig;
  }

  static get instance() {
    Config.assertConfigLoaded();
    return Config._instance;
  }
  
  static assertConfigLoaded() {
    if (!Config._instance) {
      throw new Error("internal error: configuration not available yet");
    }
  }

  static changeWindowTitle(title) {
    document.title = title;
  }

  static changeWindowIcon(iconUrl) {
    let favicon = document.querySelector('link[rel="icon"]');
    if (favicon !== null) {
      favicon.href = iconUrl;
    } else {
      favicon = document.createElement("link");
      favicon.rel = "icon";
      favicon.href = iconUrl;
      document.head.appendChild(favicon);
    }
  }

  static getAuthClientFromEnv() {
    const authority = "";
    const clientId = "";
    const audience = "";
    return { authority, clientId, audience };
  }

  static getApiServerFromEnv() {
    const id = "";
    const name = "";
    const url = "";
    return { id, name, url };
  }
}


// Array of user place colors in stable order (see #153)
export const userPlaceColorsArray= [
  ["red", red],
  ["yellow", yellow],
  ["blue", blue],
  ["pink", pink],
  ["lightBlue", lightBlue],
  ["green", green],
  ["orange", orange],
  ["lime", lime],
  ["purple", purple],
  ["indigo", indigo],
  ["cyan", cyan],
  ["brown", brown],
  ["teal", teal],
];

const userPlaceColors = (() => {
  const obj= {};
  userPlaceColorsArray.forEach(([name, color]) => {
    obj[name] = color;
  });
  return obj;
})();

export const userPlaceColorNames = userPlaceColorsArray.map(
  ([name, _]) => name,
);

export function getStrokeShade(paletteMode) {
  return paletteMode === "light" ? 800 : 400;
}

export function getUserPlaceColorName(index){
  return userPlaceColorNames[index % userPlaceColorNames.length];
}

export function getUserPlaceColor(
  colorName,
  paletteMode,
){
  const shade = getStrokeShade(paletteMode);
  return userPlaceColors[colorName][shade];
}

export function getUserPlaceFillOpacity(opacity){
  if (!isNumber(opacity)) {
    opacity = Config.instance.branding.polygonFillOpacity;
  }
  return isNumber(opacity) ? opacity : 0.25;
}

// See resources/maps.json
const tileAccess = {
  Mapbox: {
    param: "access_token",
    token:
      "pk.eyJ1IjoiZm9ybWFuIiwiYSI6ImNrM2JranV0bDBtenczb2szZG84djh6bWUifQ.q0UKwf4CWt5fcQwIDwF8Bg",
  },
};

export function getTileAccess(groupName) {
  return tileAccess[groupName];
}

function decodeBrandingFlag(
  branding,
  flagName,
){
  const _flagValue = appParams.get(flagName);
  const flagValue= _flagValue
    ? !!parseInt(_flagValue)
    : !!branding[flagName];
  return { ...branding, [flagName]: flagValue };
}
