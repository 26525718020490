import { default as OlGeoJSONFormat } from "ol/format/GeoJSON";

import * as api from "../api";
import i18n from "../i18n";
import {  findDataset } from "../model/dataset";
import {
  findPlaceInPlaceGroups,
  isValidPlaceGroup,
} from "../model/place";
import { renderUserColorBarAsBase64 } from "../model/userColorBar";
import {
  selectedDatasetIdSelector,
  selectedDatasetSelectedPlaceGroupsSelector,
  selectedDatasetSelector,
  selectedPlaceGroupsSelector,
  selectedPlaceIdSelector,
  selectedServerSelector,
} from "../selectors/controlSelectors";
import { datasetsSelector } from "../selectors/dataSelectors";
import {
  UPDATE_DATASET_PLACE_GROUP,
  updateDatasetPlaceGroup,
} from "./dataActions";
import { postMessage } from "./messageLogActions";
import { locateInMap } from "./mapActions";

////////////////////////////////////////////////////////////////////////////////

export const SELECT_DATASET = "SELECT_DATASET";

export function selectDataset(
  selectedDatasetId,
  datasets,
  showInMap,
) {
  return (dispatch, getState) => {
    dispatch(_selectDataset(selectedDatasetId, datasets));
    const locateMode = getState().controlState.datasetLocateMode;
    if (selectedDatasetId && showInMap && locateMode !== "doNothing") {
      dispatch(
        locateDatasetInMap(
          selectedDatasetId,
          getState().controlState.datasetLocateMode === "panAndZoom",
        ) ,
      );
    }
  };
}

export function _selectDataset(
  selectedDatasetId,
  datasets,
) {
  return { type: SELECT_DATASET, selectedDatasetId, datasets };
}

////////////////////////////////////////////////////////////////////////////////

export function locateSelectedDatasetInMap() {
  return (dispatch, getState) => {
    const datasetId = selectedDatasetIdSelector(getState());
    if (datasetId) {
      dispatch(locateDatasetInMap(datasetId, true));
    }
  };
}

export function locateSelectedPlaceInMap() {
  return (dispatch, getState) => {
    const placeId = selectedPlaceIdSelector(getState());
    if (placeId) {
      dispatch(locatePlaceInMap(placeId, true) );
    }
  };
}

export function locateDatasetInMap(
  selectedDatasetId,
  shouldZoom,
) {
  return (dispatch, getState) => {
    const datasets = datasetsSelector(getState());
    const dataset = findDataset(datasets, selectedDatasetId);
    if (dataset && dataset.bbox) {
      dispatch(locateShapeInMap(dataset.bbox, shouldZoom));
    }
  };
}

const SIMPLE_GEOMETRY_TYPES = [
  "Point",
  "LineString",
  "LinearRing",
  "Polygon",
  "MultiPoint",
  "MultiLineString",
  "MultiPolygon",
  "Circle",
];

export function locatePlaceInMap(selectedPlaceId, shouldZoom) {
  return (dispatch, getState) => {
    const placeGroups = selectedPlaceGroupsSelector(getState());
    const place = findPlaceInPlaceGroups(placeGroups, selectedPlaceId);
    if (place) {
      if (place.bbox && place.bbox.length === 4) {
        dispatch(locateShapeInMap(place.bbox, shouldZoom));
      } else if (
        place.geometry &&
        SIMPLE_GEOMETRY_TYPES.includes(place.geometry.type)
      ) {
        dispatch(
          locateShapeInMap(
            new OlGeoJSONFormat().readGeometry(place.geometry),
            shouldZoom,
          ),
        );
      }
    }
  };
}

export function locateShapeInMap(
  location,
  shouldZoom,
) {
  return (dispatch) => {
    if (location !== null) {
      const mapId = "map";
      dispatch(_flyTo(mapId, location));
      locateInMap(mapId, location, shouldZoom);
    }
  };
}

export function _updateUserColorBar(
  userColorBar,
){
  return { type: UPDATE_USER_COLOR_BAR, userColorBar };
}

////////////////////////////////////////////////////////////////////////////////

// TODO: check, seems that this action is not needed at all

export const FLY_TO = "FLY_TO";


export function _flyTo(
  mapId,
  location,
) {
  return { type: FLY_TO, mapId, location };
}

////////////////////////////////////////////////////////////////////////////////

export const SELECT_PLACE_GROUPS = "SELECT_PLACE_GROUPS";


export function selectPlaceGroups(selectedPlaceGroupIds) {
  return (
    dispatch,
    getState,
  ) => {
    const apiServer = selectedServerSelector(getState());

    dispatch(_selectPlaceGroups(selectedPlaceGroupIds));

    const dataset = selectedDatasetSelector(getState());
    const placeGroups = selectedDatasetSelectedPlaceGroupsSelector(getState());
    if (dataset !== null && placeGroups.length > 0) {
      for (const placeGroup of placeGroups) {
        if (!isValidPlaceGroup(placeGroup)) {
          const datasetId = dataset?.id;
          const placeGroupId = placeGroup.id;
          const activityId = `${UPDATE_DATASET_PLACE_GROUP}-${datasetId}-${placeGroupId}`;
          dispatch(addActivity(activityId, i18n.get("Loading places")));
          api
            .getDatasetPlaceGroup(
              apiServer.url,
              datasetId,
              placeGroupId,
              getState().userAuthState.accessToken,
            )
            .then((placeGroup) => {
              dispatch(updateDatasetPlaceGroup(dataset?.id, placeGroup));
            })
            .catch((error) => {
              dispatch(postMessage("error", error));
            })
            .finally(() => {
              dispatch(removeActivity(activityId));
            });
        }
      }
    }
  };
}

export function _selectPlaceGroups(
  selectedPlaceGroupIds,
) {
  return { type: SELECT_PLACE_GROUPS, selectedPlaceGroupIds };
}

////////////////////////////////////////////////////////////////////////////////

export const SELECT_PLACE = "SELECT_PLACE";

export function selectPlace(placeId, places, showInMap) {
  return function(dispatch, getState) {
    dispatch(_selectPlace(placeId, places));
    const locateMode = getState().controlState.placeLocateMode;
    if (showInMap && placeId && locateMode !== "doNothing") {
      dispatch(
        locatePlaceInMap(
          placeId,
          getState().controlState.placeLocateMode === "panAndZoom"
        )
      );
    }
  };
}

function _selectPlace(placeId, places) {
  return { type: SELECT_PLACE, placeId, places };
}

////////////////////////////////////////////////////////////////////////////////

export const SET_LAYER_VISIBILITY = "SET_LAYER_VISIBILITY";

export function setLayerVisibility(
  layerId,
  visible,
){
  return { type: SET_LAYER_VISIBILITY, layerId, visible };
}

////////////////////////////////////////////////////////////////////////////////

export const SET_MAP_POINT_INFO_BOX_ENABLED = "SET_MAP_POINT_INFO_BOX_ENABLED";


export function setMapPointInfoBoxEnabled(
  mapPointInfoBoxEnabled,
){
  return {
    type: SET_MAP_POINT_INFO_BOX_ENABLED,
    mapPointInfoBoxEnabled,
  };
}

////////////////////////////////////////////////////////////////////////////////

export const SET_TOP_BAR_ENABLED = "SET_TOP_BAR_ENABLED";


export function setTopbarEnabled(
  topBarEnabled,
){
  return {
    type: SET_TOP_BAR_ENABLED,
    topBarEnabled,
  };
}

////////////////////////////////////////////////////////////////////////////////

export const SET_VARIABLE_COMPARE_MODE = "SET_VARIABLE_COMPARE_MODE";

export function setVariableCompareMode(
  variableCompareMode,
){
  return {
    type: SET_VARIABLE_COMPARE_MODE,
    variableCompareMode,
  };
}

////////////////////////////////////////////////////////////////////////////////

export const SET_VARIABLE_SPLIT_POS = "SET_VARIABLE_SPLIT_POS";

export function setVariableSplitPos(
  variableSplitPos,
){
  return {
    type: SET_VARIABLE_SPLIT_POS,
    variableSplitPos,
  };
}

////////////////////////////////////////////////////////////////////////////////

export const SELECT_VARIABLE = "SELECT_VARIABLE";

export function selectVariable(
  selectedVariableName,
){
  return { type: SELECT_VARIABLE, selectedVariableName };
}

////////////////////////////////////////////////////////////////////////////////

export const SELECT_VARIABLE_2 = "SELECT_VARIABLE_2";

export function selectVariable2(
  selectedDataset2Id,
  selectedVariable2Name,
){
  return {
    type: SELECT_VARIABLE_2,
    selectedDataset2Id,
    selectedVariable2Name,
  };
}

////////////////////////////////////////////////////////////////////////////////

export const SELECT_TIME = "SELECT_TIME";

export function selectTime(selectedTime){
  return { type: SELECT_TIME, selectedTime };
}

////////////////////////////////////////////////////////////////////////////////

export const INC_SELECTED_TIME = "INC_SELECTED_TIME";

export function incSelectedTime(increment){
  return { type: INC_SELECTED_TIME, increment };
}

////////////////////////////////////////////////////////////////////////////////

export const SELECT_TIME_RANGE = "SELECT_TIME_RANGE";

export function selectTimeRange(
  selectedTimeRange,
  selectedGroupId,
  selectedValueRange,
) {
  return {
    type: SELECT_TIME_RANGE,
    selectedTimeRange,
    selectedGroupId,
    selectedValueRange,
  };
}

////////////////////////////////////////////////////////////////////////////////

// TODO (forman): this action doesn't seem to be in use - remove!

export const SELECT_TIME_SERIES_UPDATE_MODE = "SELECT_TIME_SERIES_UPDATE_MODE";

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_TIME_ANIMATION = "UPDATE_TIME_ANIMATION";

export function updateTimeAnimation(
  timeAnimationActive,
  timeAnimationInterval,
) {
  return {
    type: UPDATE_TIME_ANIMATION,
    timeAnimationActive,
    timeAnimationInterval,
  };
}

////////////////////////////////////////////////////////////////////////////////

export const SET_MAP_INTERACTION = "SET_MAP_INTERACTION";

export function setMapInteraction(
  mapInteraction,
){
  return { type: SET_MAP_INTERACTION, mapInteraction };
}

////////////////////////////////////////////////////////////////////////////////

export const SET_LAYER_MENU_OPEN = "SET_LAYER_MENU_OPEN";


export function setLayerMenuOpen(layerMenuOpen){
  return { type: SET_LAYER_MENU_OPEN, layerMenuOpen };
}

////////////////////////////////////////////////////////////////////////////////

export const SET_SIDEBAR_POSITION = "SET_SIDEBAR_POSITION";


export function setSidebarPosition(
  sidebarPosition,
){
  return { type: SET_SIDEBAR_POSITION, sidebarPosition };
}

////////////////////////////////////////////////////////////////////////////////

export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";

export function setSidebarOpen(sidebarOpen){
  return { type: SET_SIDEBAR_OPEN, sidebarOpen };
}

////////////////////////////////////////////////////////////////////////////////

export const SET_SIDEBAR_PANEL_ID = "SET_SIDEBAR_PANEL_ID";

export function setSidebarPanelId(
  sidebarPanelId,
){
  return { type: SET_SIDEBAR_PANEL_ID, sidebarPanelId };
}

////////////////////////////////////////////////////////////////////////////////

export const SET_VOLUME_RENDER_MODE = "SET_VOLUME_RENDER_MODE";

export function setVolumeRenderMode(
  volumeRenderMode,
){
  return { type: SET_VOLUME_RENDER_MODE, volumeRenderMode };
}

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_VOLUME_STATE = "UPDATE_VOLUME_STATE";

export function updateVolumeState(
  volumeId,
  volumeState,
){
  return { type: UPDATE_VOLUME_STATE, volumeId, volumeState };
}

////////////////////////////////////////////////////////////////////////////////

export const SET_VISIBLE_INFO_CARD_ELEMENTS = "SET_VISIBLE_INFO_CARD_ELEMENTS";

export function setVisibleInfoCardElements(
  visibleElements,
){
  return { type: SET_VISIBLE_INFO_CARD_ELEMENTS, visibleElements };
}

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_INFO_CARD_ELEMENT_VIEW_MODE =
  "UPDATE_INFO_CARD_ELEMENT_VIEW_MODE";


export function updateInfoCardElementViewMode(
  elementType,
  viewMode,
){
  return { type: UPDATE_INFO_CARD_ELEMENT_VIEW_MODE, elementType, viewMode };
}

////////////////////////////////////////////////////////////////////////////////

export const ADD_ACTIVITY = "ADD_ACTIVITY";

export function addActivity(id, message){
  return { type: ADD_ACTIVITY, id, message };
}

////////////////////////////////////////////////////////////////////////////////

export const REMOVE_ACTIVITY = "REMOVE_ACTIVITY";

export function removeActivity(id){
  return { type: REMOVE_ACTIVITY, id };
}

////////////////////////////////////////////////////////////////////////////////

export const CHANGE_LOCALE = "CHANGE_LOCALE";

export function changeLocale(locale){
  return { type: CHANGE_LOCALE, locale };
}

////////////////////////////////////////////////////////////////////////////////

export const OPEN_DIALOG = "OPEN_DIALOG";

export function openDialog(dialogId){
  return { type: OPEN_DIALOG, dialogId };
}

////////////////////////////////////////////////////////////////////////////////

export const CLOSE_DIALOG = "CLOSE_DIALOG";


export function closeDialog(dialogId){
  return { type: CLOSE_DIALOG, dialogId };
}

////////////////////////////////////////////////////////////////////////////////

export const UPDATE_SETTINGS = "UPDATE_SETTINGS";

export function updateSettings(
  settings,
){
  return { type: UPDATE_SETTINGS, settings };
}

////////////////////////////////////////////////////////////////////////////////

export const STORE_SETTINGS = "STORE_SETTINGS";

export function storeSettings() {
  return { type: STORE_SETTINGS };
}

////////////////////////////////////////////////////////////////////////////////

export function addUserColorBar(colorBarId) {
  return (dispatch) => {
    dispatch(_addUserColorBar(colorBarId));
    dispatch(updateUserColorBarImageDataById(colorBarId));
  };
}

export const ADD_USER_COLOR_BAR = "ADD_USER_COLOR_BAR";

export function _addUserColorBar(colorBarId){
  return { type: ADD_USER_COLOR_BAR, colorBarId };
}


export const REMOVE_USER_COLOR_BAR = "REMOVE_USER_COLOR_BAR";

export function removeUserColorBar(colorBarId){
  return { type: REMOVE_USER_COLOR_BAR, colorBarId };
}

////////////////////////////////////////////////////////////////////////////////

export function updateUserColorBar(userColorBar) {
  return (dispatch) => {
    dispatch(_updateUserColorBar(userColorBar));
    dispatch(updateUserColorBarImageData(userColorBar));
  };
}

export const UPDATE_USER_COLOR_BAR = "UPDATE_USER_COLOR_BAR";

////////////////////////////////////////////////////////////////////////////////

function updateUserColorBarImageDataById(colorBarId) {
  return function(dispatch, getState) {
    const colorBar = getState().controlState.userColorBars.find(
      (ucb) => ucb.id === colorBarId,
    );
    if (colorBar) {
      dispatch(updateUserColorBarImageData(colorBar));
    }
  };
}

function updateUserColorBarImageData(colorBar) {
  return (dispatch) => {
    renderUserColorBarAsBase64(colorBar).then(({ imageData, errorMessage }) => {
      dispatch(_updateUserColorBar({ ...colorBar, imageData, errorMessage }));
    });
  };
}

////////////////////////////////////////////////////////////////////////////////

export function updateUserColorBarsImageData() {
  return function(dispatch, getState) {
    getState().controlState.userColorBars.forEach((colorBar) => {
      if (!colorBar.imageData) {
        dispatch(updateUserColorBarImageData(colorBar));
      }
    });
  };
}


////////////////////////////////////////////////////////////////////////////////

export function updateUserColorBars(
  userColorBars,
){
  return { type: UPDATE_SETTINGS, settings: { userColorBars } };
}
