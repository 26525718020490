import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import riskwolfLogo from "../../assets/images/logo-lg.svg";
import digisafeLogo from "../../assets/images/digiSafe.png";
import pioneerLogo from "../../assets/images/pioneerLogo.png";
import hdfcErgoLogo from "../../assets/images/HDFCLOGO1.png";
import iciciLombardLogo from "../../assets/images/iciciLombardLogo.png";

const HeaderExternal = (props) => {
  const { tenantHandle } = props
  const useStyles = makeStyles((theme) => ({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      color: "#FFFFFF",
      fontSize: 18,
      fontWeight: 700,
      marginLeft: 20,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 100,
      boxShadow: "none",
      height: 64,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      paddingLeft: 50,
      paddingRight: 20,
      gap: 30,
    },
    logo: {
      height: 42,
      width: 90,
      background: "white",
      padding: 10,
      borderRadius: 12,
    },
    logoImg: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      maxWidth: 120,
    },
  }));

  const classes = useStyles();

  const getLogo = (tenant) => {
    switch (tenant) {
      case "riskwolf":
        return riskwolfLogo;
      case "digisafe":
        return digisafeLogo;
      case "pioneer":
        return pioneerLogo;
      case "hdfc_ergo":
        return hdfcErgoLogo;
      case "icici_lombard":
        return iciciLombardLogo;
      default:
        return null;
    }
  };

  return (
    <header>
      <AppBar position="fixed" className={classes.appBar}>
        {tenantHandle && getLogo(tenantHandle) ? (
          <div className={classes.logo}>
            <img src={getLogo(tenantHandle)} alt="Tenant Logo" className={classes.logoImg} />
          </div>
        ) : (
          ""
        )}
        <Typography className={classes.title}> Daily Index Monitoring </Typography>
      </AppBar>
    </header>
  );
};

export default HeaderExternal;
