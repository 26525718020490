

import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";

import i18n from "../i18n";
import { UNIT } from "../model/timeSeries";
import {
  utcTimeToIsoDateString,
  utcTimeToIsoDateTimeString,
} from "../util/time";
import { makeStyles } from "../util/styles";

const HOR_MARGIN = 5;

// noinspection JSUnusedLocalSymbols
const styles = makeStyles({
  box: (theme) => ({
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(HOR_MARGIN),
    marginRight: theme.spacing(HOR_MARGIN),
    minWidth: 200,
  }),
  label: {
    color: "grey",
    fontSize: "1em",
  },
});

export default function TimeSlider({
  hasTimeDimension,
  selectedTime,
  selectTime,
  selectedTimeRange,
}) {
  const [selectedTime_, setSelectedTime_] = useState(selectedTime);

  useEffect(() => {
    setSelectedTime_(
      selectedTime || (selectedTimeRange ? selectedTimeRange[0] : 0),
    );
  }, [selectedTime, selectedTimeRange]);

  if (!hasTimeDimension) {
    return null;
  }

  const handleChange = (_event, value) => {
    if (typeof value === "number") {
      setSelectedTime_(value);
    }
  };

  const handleChangeCommitted = (
    _event,
    value,
  ) => {
    if (selectTime && typeof value === "number") {
      selectTime(value);
    }
  };

  const selectedTimeRangeValid = Array.isArray(selectedTimeRange);
  if (!selectedTimeRangeValid) {
    selectedTimeRange = [Date.now() - 2 * UNIT.years, Date.now()];
  }

  const marks= [
    {
      value: selectedTimeRange[0],
      label: utcTimeToIsoDateString(selectedTimeRange[0]),
    },
    {
      value: selectedTimeRange[1],
      label: utcTimeToIsoDateString(selectedTimeRange[1]),
    },
  ];

  function valueLabelFormat(value) {
    return utcTimeToIsoDateTimeString(value);
  }

  return (
    <Box sx={styles.box}>
      <Tooltip arrow title={i18n.get("Select time in dataset")}>
        <Slider
          disabled={!selectedTimeRangeValid}
          min={selectedTimeRange[0]}
          max={selectedTimeRange[1]}
          value={selectedTime_ || 0}
          valueLabelDisplay="off"
          valueLabelFormat={valueLabelFormat}
          marks={marks}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          size="small"
        />
      </Tooltip>
    </Box>
  );
}
