
import { styled } from "@mui/system";

const ControlBarForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  flexGrow: 0,
  gap: "20px",
}));

export default function ControlBar({
  children,
}) {
  return <ControlBarForm autoComplete="off">{children}</ControlBarForm>;
}
