import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ToggleButton from "@mui/material/ToggleButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PolicyIcon from "@mui/icons-material/Policy";
import { useState } from "react";
import MarkdownPage from "../components/MarkdownPage";

import i18n from "../i18n";
import { commonStyles } from "../components/common-styles";

// noinspection JSUnusedLocalSymbols
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(0.5),
  marginLeft: "auto",
}));

export default function ControlBarActions({
  visible,
  sidebarOpen,
  setSidebarOpen,
  openDialog,
  allowRefresh,
  updateResources,
  compact,
}) {
  const [imprintOpen, setImprintOpen] = useState(false);

  const handleOpenManual = () => {
    window.open("https://xcube.readthedocs.io/en/latest/viewer.html", "Manual");
  };

  const handleOpenImprint = () => {
    setImprintOpen(true);
  };

  const handleCloseImprint = () => {
    setImprintOpen(false);
  };

  if (!visible) {
    return null;
  }

  const sidebarButton = (
    <ToggleButton
      value={"sidebar"}
      selected={sidebarOpen}
      onClick={() => setSidebarOpen(!sidebarOpen)}
      size="small"
      sx={commonStyles.toggleButton}
    >
      <Tooltip arrow title={i18n.get("Show or hide sidebar")}>
        {<ViewSidebarIcon />}
      </Tooltip>
    </ToggleButton>
  );

  const helpButton = (
    <Tooltip arrow title={i18n.get("Help")}>
      <IconButton onClick={handleOpenManual} size="small">
        <HelpOutlineIcon />
      </IconButton>
    </Tooltip>
  );

  const imprintButton = (
    <Tooltip arrow title={i18n.get("Imprint")}>
      <IconButton onClick={handleOpenImprint} size="small">
        <PolicyIcon />
      </IconButton>
    </Tooltip>
  );

  const markdownPage = (
    <MarkdownPage
      title={i18n.get("Imprint")}
      href="docs/imprint.md"
      open={imprintOpen}
      onClose={handleCloseImprint}
    />
  );

  let refreshButton;
  let downloadButton;
  let settingsButton;

  refreshButton = (
    <IconButton onClick={updateResources} size="small">
      <Tooltip arrow title={i18n.get("Refresh")}>
        <RefreshIcon />
      </Tooltip>
    </IconButton>
  );

  downloadButton = (
    <IconButton onClick={() => openDialog("export")} size="small">
      <Tooltip arrow title={i18n.get("Export data")}>
        {<CloudDownloadIcon />}
      </Tooltip>
    </IconButton>
  );

  settingsButton = (
    <IconButton onClick={() => openDialog("settings")} size="small">
      <Tooltip arrow title={i18n.get("Settings")}>
        <SettingsIcon />
      </Tooltip>
    </IconButton>
  );

  return (
    <StyledFormControl variant="standard">
      <Box>
        {refreshButton}
        {downloadButton}
        {helpButton}
        {imprintButton}
        {settingsButton}
        {sidebarButton}
        {markdownPage}
      </Box>
    </StyledFormControl>
  );
}
