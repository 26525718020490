import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import ToolboxPolicies from '../Toolbox/ToolboxPolicies';
import PoliciesTable from './PoliciesTable';
import formatMissingValue from '../../Util/formatMissingValue';
import Format from '../../Util/format';
import { formatNumber } from '../../Util/formatNumber';
import Tooltip from '@mui/material/Tooltip';
import { getTitleTooltipcurrency } from "../../Util/getTitleTooltipcurrency";

const PoliciesCard = props => {
  const {title, content, loaded, currency} = props;

  const useStyles = makeStyles(theme => ({
    root: {
      padding: `${theme.typography.pxToRem(30)} ${theme.typography.pxToRem(16)}`,
      borderRadius: 12,
      boxShadow: '0px 3px 12px 0px #0000001F',
      backgroundColor: 'white',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontWeight: 700,
      fontSize: 48,
      textTransform: 'uppercase',
      marginBottom: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
    subtitle: {
      fontWeight: 700,
      fontSize: 20,
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        <Tooltip title={getTitleTooltipcurrency(title, currency)}>
          {loaded ? formatMissingValue(title) : <Skeleton/>}
        </Tooltip>  
      </Typography>
      <Typography className={classes.subtitle}>{content}</Typography>
    </div>
  );
};

const PoliciesCards = (props) => {
  const { totalSumInsured, numberOfClaims, grossWrittenPremiums, avgPremiumRate, loaded, currency } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: theme.spacing(2),
    },
    col: {
      display: "flex",
      minWidth: 250,
      flexGrow: 1,
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="false">
        <Grid container spacing={2}>
          <Grid item sm={6} md={3} className={classes.col}>
            <PoliciesCard title={numberOfClaims} content="Number of policies" loaded={loaded} currency={''}/>
          </Grid>
          <Grid item sm={6} md={3} className={classes.col}>
            <PoliciesCard
              title={`${currency} ${formatNumber(Number(grossWrittenPremiums.toFixed(2)), currency)}`}
              content="Gross written premiums"
              loaded={loaded}
              currency={currency}
            />
          </Grid>
          <Grid item sm={6} md={3} className={classes.col}>
            <PoliciesCard title={`${currency} ${formatNumber(Number(totalSumInsured.toFixed(2)), currency)}`} content="Total sum insured" loaded={loaded} currency={currency}/>
          </Grid>
          <Grid item sm={6} md={3} className={classes.col}>
            <PoliciesCard title={Format.percentageWrapFormat(avgPremiumRate)} content="Average premium rate" loaded={loaded} currency={''}/>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const Policies = (props) => {
  const {
    policies,
    policySummary,
    loaded,
    page,
    rowsPerPage,
    totalPages,
    totalElements,
    onChangePage,
  } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingBottom: theme.spacing(6),
    },
  }));

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Toolbar />
      <ToolboxPolicies />
      <PoliciesCards
        loaded={loaded}
        totalSumInsured={policySummary?.totalSumInsured ?? 0}
        numberOfClaims={policySummary?.numberOfClaims ?? 0}
        grossWrittenPremiums={policySummary?.grossWrittenPremiums ?? 0}
        avgPremiumRate={policySummary?.avgPremiumRate ?? 0}
        currency={policySummary?.currency ?? ''}
      />
      <PoliciesTable
        {...{ policies, loaded, page, rowsPerPage, totalPages, totalElements, onChangePage }}
      />
    </Box>
  );
};

export default Policies;
