import * as React from "react";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import i18n from "../i18n";
import { localToUtcTime, utcTimeToLocal } from "../util/time";
import ControlBarItem from "./ControlBarItem";

// noinspection JSUnusedLocalSymbols
const styles = (theme) =>
  createStyles({
    dateTimePicker: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
    },
  });


const _TimeSelect= ({
  classes,
  hasTimeDimension,
  selectedTime,
  selectedTimeRange,
  selectTime,
}) => {
  const handleTimeChange = (date) => {
    selectTime(date !== null ? localToUtcTime(date) : null);
  };

  const timeInputLabel = (
    <InputLabel shrink htmlFor="time-select">
      {`${i18n.get("Time")} (UTC)`}
    </InputLabel>
  );

  const isValid = typeof selectedTime === "number";
  const timeValue = isValid ? utcTimeToLocal(selectedTime) : null;

  let minTimeValue, maxTimeValue;
  if (Array.isArray(selectedTimeRange)) {
    minTimeValue = utcTimeToLocal(selectedTimeRange[0]);
    maxTimeValue = utcTimeToLocal(selectedTimeRange[1]);
  }

  const timeInput = (
    <LocalizationProvider dateAdapter={AdapterDateFns} className={classes.dateTime}>
      <DateTimePicker
        disabled={!hasTimeDimension}
        className={classes.dateTimePicker}
        inputFormat="yyyy-MM-dd hh:mm:ss"
        value={timeValue}
        minDateTime={minTimeValue}
        maxDateTime={maxTimeValue}
        onChange={handleTimeChange}
        ampm={false}
        renderInput={(props) => (
          <TextField {...props} variant="standard" size="small" />
        )}
        slotProps={{ textField: { size: 'small' } }}
      />
    </LocalizationProvider>
  );

  return <ControlBarItem label={timeInputLabel} control={timeInput} />;
};

const TimeSelect = withStyles(styles)(_TimeSelect);
export default TimeSelect;
