export function isNullStatistics(s){
  return s.count === 0;
}

export function isPointStatistics(s) {
  return s.count === 1;
}

export function isAreaStatistics(s){
  return s.count > 1;
}
