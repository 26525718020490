import React, { useMemo } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import EventDetailsExternal from "../EventDetailsExternal/EventDetailsExternal"
import Login from "../../Components/Login/Login";
import EventExternal from "../EventExternal/EventExternal";

const getRoutes = () => {
  return [
    {
      path: "/m/:id",
      component: EventDetailsExternal,
      hasAccess: true,
    },
    {
      path: "/m",
      component: EventExternal,
      hasAccess: true,
    },
  ];
};

const PortalExternal = ({ theme }) => {

  const mainStyles = useMemo(
    () => ({
      base: {
        transition: `width ${theme.transitions.duration.leavingScreen}ms , margin ${theme.transitions.duration.leavingScreen}ms`,
      },
      active: {
        marginLeft: -theme.drawerWidth,
      },
    }),
    [theme],
  );

  const routes = useMemo(() => getRoutes(), []);

  const currentPath = window.location.pathname;

  const checkPath = currentPath.includes('/m/');
  if (checkPath) {
    return (
      <BrowserRouter>
        <main className="main" style={mainStyles.base}>
          <Switch>
            {routes
              .filter((route) => route.hasAccess)
              .map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
          </Switch>
        </main>
      </BrowserRouter>
    );
  }
  return(
    <Login />
  )
};

export default PortalExternal;
